import React from 'react';
import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';
import FlexCard from '../../components/mini/flexcard';
import GiftCardBackground from '../../components/giftcardbackground';
import settings from '../../../settings';


// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/mini/creditcard/mini-banner.jpg`;
const creditCard = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-cc-home.jpg`
const CreditCard = () => {
  return (
    <Layout>
      <SEO title="MINI Driving Experience USA | MINI Driving School" description="Over 50 years of motorsport legacy goes into the MINI Driving Experience in the form of Group Events, Stunt Driving School and autocross and track school." />
      <div className="giftcardpage giftcardpagemini">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />
          <section className="drivingschool performance-center">
            <div className="container">
              <div className="performance-center__header">
                <h2 >Gift Cards</h2>
              </div>
            </div>
            <FlexCard
              rowType="row"
              image={creditCard}
              imageAlt="FUEL THE FUN"
              header="FUEL THE FUN"
              subheader="SAVE 20% ON CLASSES WITH THE MINI CARD"
              btnText="Learn More"
              btnStyle="btn-black"
              externalURL="https://www.myminicreditcard.com/35343"
            >
              <p>
                Accelerate your rewards with the MINI Card – exclusively for MINI drivers. Redeem for unlimited cash back toward your MINI Financial Services monthly payment or travel. Plus, you’ll save 20% when you book a class with us over the phone.
              </p>
            </FlexCard>
          </section>
        </div>
    </Layout>
  );
};

export default CreditCard;
